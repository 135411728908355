import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import heroImg from "../images/web-dev.svg";
import video1 from "../images/videos/fogf.mp4";

import VideoSlider from "./VideoSlider";

const Hero = () => {
  const scrollToSection = () => {
    console.log("heihei");
    const section = document.getElementById("services");
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 85,
        behavior: "smooth", // Optional: Smooth scrolling animation
      });
    }
  };

  return (
    <>
      <div className="hero" id="hero">
        <video
          className="video"
          id="video1"
          src={video1}
          autoPlay
          loop
          muted
          playsInline
        ></video>

        <div>
          <NavBar />
        </div>
        <div className="videocontent">
          <h1 className="heading-video"> KALI SIKARAN </h1>
          <h1 className="heading-video-under-text text-center">
            Dette er selvforsvar!
          </h1>
          <div>
            <button className="locationbutton" onClick={scrollToSection}>
              Finn din klubb
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
